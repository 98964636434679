.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 4em;
  max-width: 1800px;
}

.footer__content {
  text-align: center;
  margin: 1em;
  font-size: 1em;
  color: #151413;
}

@media screen and (max-width: 430px) {
  .footer__content {
    font-size: 0.8em;
  }
}
