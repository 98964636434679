.content__fun-fact {
  display: flex;
  margin: 2em 0;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.fun-fact__heading {
  margin: 0 auto 0 1em;
  font-family: "LoveYaLikeASister", cursive;
  font-size: 2em;
  color: #151413;
}

.fun-fact__list {
  margin: 1em;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2em;
  align-self: center;
  max-width: 1050px;
  color: #151413;
}

.fun-fact__list-item {
  padding: 1em;
  text-align: center;
  background: transparent;
  border-radius: 10px 50px/50px 10px;
  border: solid 5px #151413;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fun-fact__frame {
  background-color: white;
  padding: 25px;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  margin: 1em 0;
}
.fun-fact__tape {
  position: absolute;
  top: -1em;
  left: 26%;
  width: 50px;
  height: 25px;
  background: #dbd0e4;
  background-image: radial-gradient(#fff 20%, transparent 0),
    radial-gradient(#fff 20%, transparent 0);
  background-position: 0 0, 10px 10px;

  background-size: 20px 20px;
  border-left: 2px dotted rgba(0, 0, 0, 0.1);
  border-right: 2px dotted rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 0.25em 2em;
  color: #593869;
  transform: rotate(-4deg);
}

.fun-fact__photo {
  width: 250px;
  height: 250px;
}

.fun-fact__icons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 1em;
  padding: 0;
}

.fun-fact__icon {
  width: 5em;
  height: auto;
}

@media screen and (max-width: 600px) {
  .fun-fact__photo {
    width: 200px;
    height: 200px;
  }
  .fun-fact__icon {
    width: 4em;
    height: auto;
  }
}
@media screen and (max-width: 500px) {
  .fun-fact__photo {
    width: 180px;
    height: 180px;
  }
  .fun-fact__icon {
    width: 3.3em;
    height: auto;
  }
}
