.side-bar {
  border-right: 3px solid #ff9999;
  background: transparent;
  position: fixed;
  width: 20%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  max-width: 310px;
}

.side-bar__list {
  list-style-type: none;
  padding: 0;
  margin: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.side-bar__list-item {
  font-weight: bold;
  background-color: #acffac;
  padding: 3%;
  position: relative;
  margin: 0;
  width: 70%;
}

.side-bar__list-item::before {
  position: absolute;
  bottom: -5px;
  right: 7px;
  z-index: -1;
  transform: rotate(5deg);
  width: 70%;
  height: 70%;
  background-color: #d0d0d0;
  content: "";
  filter: blur(4px);
  transition: right 0.5s ease-in 0.2s, bottom 0.5s ease-in 0.2s;
}

.side-bar__list-item:hover::before {
  right: 25px;
  bottom: -10px;
  transition: right 0.5s ease-in 0.2s, bottom 0.5s ease-in 0.2s;
}

.side-bar__list-item_linkedin {
  background-color: #c1e0ff;
}

.side-bar__list-item_project {
  background-color: #ffacac;
  margin-bottom: 2em;
}
.side-bar__social-link {
  color: #151413;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1em;
}

.side-bar__social-icon {
  height: 35px;
  width: 35px;
  margin-right: 10px;
}
@media screen and (max-width: 1200px) {
  .side-bar__social-link {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 900px) {
  .side-bar__social-link {
    flex-direction: column;
  }
  .side-bar__social-icon {
    margin-right: 0px;
  }
}
@media screen and (max-width: 600px) {
  .side-bar__list {
    margin: 5px;
  }
  .side-bar__list-item {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 430px) {
  .side-bar {
    border-right: none;
    position: sticky;
    width: 100%;
    height: fit-content;
  }
  .side-bar__list {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: space-evenly;
  }
  .side-bar__list-item_project {
    margin-bottom: 0;
  }
  .side-bar__social-icon {
    height: 25px;
    width: 25px;
  }
}
