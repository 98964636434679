@font-face {
  font-family: "Edu TAS Beginner";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./EduTASBeginner-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Edu TAS Beginner";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("./EduTASBeginner-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LoveYaLikeASister";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./LoveYaLikeASister-Regular.ttf") format("truetype");
}
