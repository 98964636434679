.project-card__list-item {
  margin: 30px 0;
  position: relative;
  background: #f8f0d7;
  border-top: 10px dotted rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 25px;
  color: #65513f;
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: box-shadow 0.3s linear 0s;
}

.project-card__list-item:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s linear 0s;
}
.project-card__tape {
  position: absolute;
  top: -1em;
  left: 26%;
  width: 50px;
  height: 25px;
  background-color: #fff;
  background-image: linear-gradient(
      -45deg,
      rgba(253, 151, 151, 0.5) 25%,
      transparent 25%,
      transparent 50%,
      rgba(253, 151, 151, 0.5) 50%,
      rgba(253, 151, 151, 0.5) 75%,
      transparent 70%,
      transparent 80%
    ),
    linear-gradient(
      45deg,
      rgba(253, 151, 151, 0.5) 25%,
      transparent 25%,
      transparent 50%,
      rgba(253, 151, 151, 0.5) 50%,
      rgba(253, 151, 151, 0.5) 75%,
      transparent 70%,
      transparent 80%
    );
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
  border-left: 2px dotted rgba(0, 0, 0, 0.1);
  border-right: 2px dotted rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 0.25em 2em;
  color: #593869;
  transform: rotate(-4deg);
  visibility: visible;
}

.project-card__list-item:hover .project-card__tape {
  visibility: hidden;
  transition: visibility 0s linear 0s;
}

.project-card__screenshot {
  height: 300px;
  width: 300px;
  object-fit: cover;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin: 0 0 1em;
  align-self: center;
  justify-self: center;
}

.project-card__title {
  color: #151413;
  font-weight: bold;
  margin: 0;
  width: 300px;
  font-size: 1.2em;
  line-height: 47px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1000px) {
  .project-card__screenshot {
    height: 250px;
    width: 250px;
  }
  .project-card__title {
    font-size: 1em;
    width: 250px;
  }
}
