.main {
  margin: 0;
  background: repeating-linear-gradient(
    180deg,
    #48c8c4,
    #48c8c4 0.01em,
    #faf0e6 0.1em,
    #faf0e6 1.1em
  );
  line-height: normal;
}

.main__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 auto;
  width: 80%;
}

@media screen and (max-width: 430px) {
  .main__content {
    margin: 0 auto;
    width: 100%;
  }
}
