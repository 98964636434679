.content__self-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 0.8em;
  margin: 1.5em 0;
  width: 100%;
}
.self-info__frame {
  background-color: white;
  padding: 25px;
  width: fit-content;
  height: fit-content;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  margin: 0;
}
.self-info__tape {
  position: absolute;
  top: -1em;
  left: 26%;
  width: 80px;
  height: 30px;
  background-image: linear-gradient(
    -45deg,
    rgba(245, 124, 100, 0.4) 25%,
    transparent 25%,
    transparent 50%,
    rgba(245, 124, 100, 0.4) 50%,
    rgba(245, 124, 100, 0.4) 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 20px 20px;
  border-left: 2px dotted rgba(0, 0, 0, 0.1);
  border-right: 2px dotted rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 0.25em 2em;
  color: #65513f;
  transform: rotate(-4deg);
}
.self-info__img {
  width: 300px;
  height: 300px;
  margin: 0;
}
.self-info__texts {
  max-width: 750px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.self-info__text {
  margin: 0;
  font-size: 1.1em;
  align-self: center;
  text-align: start;
  width: auto;
  color: #120b04;
  line-height: normal;
  text-indent: 1.1em;
}

.self-info__link {
  text-decoration: none;
  color: #120b04;
  font-weight: bold;
  background: linear-gradient(transparent 50%, #ff9999 0%);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 0 100%;
  transition: all 0.8s ease;
  cursor: pointer;
}

.self-info__link:hover {
  background-size: 100% 100%;
}

@media screen and (max-width: 1475px) {
  .self-info__text {
    width: 680px;
  }
  .self-info__img {
    width: 280px;
    height: 280px;
  }
}

@media screen and (max-width: 1340px) {
  .self-info__text {
    width: 630px;
    font-size: 0.8em;
  }
  .self-info__img {
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 1000px) {
  .content__self-info {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}
@media screen and (max-width: 800px) {
  .self-info__text {
    width: 500px;
  }
}
@media screen and (max-width: 650px) {
  .self-info__text {
    width: 430px;
  }
}
@media screen and (max-width: 550px) {
  .self-info__text {
    width: 350px;
  }
}
@media screen and (max-width: 470px) {
  .self-info__text {
    width: 300px;
  }
}
@media screen and (max-width: 430px) {
  .self-info__text {
    width: 350px;
  }
  .self-info__img {
    width: 230px;
    height: 230px;
  }
}
