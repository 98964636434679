@import url(../../vendor/fonts.css);
.App {
  background-color: #faf0e6;
  font-family: "Edu TAS Beginner", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
