.content__project {
  margin: 0;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  /* align-self: flex-start; */
  /* padding: 1em; */
  max-width: 1230px;
}

.project__heading {
  margin: 0.6em auto 0.6em 0.6em;
  font-family: "LoveYaLikeASister", cursive;
  font-size: 2em;
  width: fit-content;
  line-height: normal;
  color: #151413;
}

.project__list {
  margin: 1em 0;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-evenly;
}

@media screen and (max-width: 1370px) {
  .project__heading {
    font-size: 1.8em;
  }
}
@media screen and (max-width: 1250px) {
  .project__heading {
    font-size: 1.6em;
  }
}
@media screen and (max-width: 600px) {
  .project__heading {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 480px) {
  .project__heading {
    font-size: 1.1em;
  }
}
