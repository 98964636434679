.project-modal__screenshot {
  margin: 1em 0;
  height: 300px;
  width: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.project-modal__link {
  cursor: pointer;
}

.project-modal__description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  font-size: 1em;
  color: #151413;
}

@media screen and (max-width: 900px) {
  .project-modal__screenshot {
    height: 280px;
    width: auto;
  }
  .project-modal__description {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 750px) {
  .project-modal__screenshot {
    height: auto;
    width: 500px;
  }
}
@media screen and (max-width: 640px) {
  .project-modal__screenshot {
    height: auto;
    width: 400px;
  }
}
@media screen and (max-width: 550px) {
  .project-modal__screenshot {
    height: auto;
    width: 320px;
  }
  .project-modal__description {
    font-size: 0.6em;
  }
}
@media screen and (max-width: 430px) {
  .project-modal__screenshot {
    height: auto;
    width: 270px;
  }
}
